//React
import React from 'react';

//Css
import './Support.css';

//Assets
import SupportImages from '../../assets/contact.png';

export default function Support() {
  return (
    <div className="support">
      <div className="support_wrapper">
        <div className="support_images">
          <img src={SupportImages} alt="" />
        </div>
        <div className="support_content">
          <div className="support_content_title">
            <p>Canli Destek</p>
          </div>
          <div className="support_content_content">
            <p>
              Aşağidaki iletişime geç butonuna tiklayarak ya da +90 507 883 8242
              numarali telefon ile bizimle Whatsapp üzerinden iletişim
              kurabilirsiniz. Tüm şikayet, öneri, abonelik kodu satin alma gibi
              işlemleriniz için bu numarayi kullanabilirsiniz.
            </p>
          </div>
          <div className="support_content_button">
            <button>İletişime Geç</button>
          </div>
        </div>
      </div>
    </div>
  );
}
