import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as apis from '../api';

export const login = createAsyncThunk(
  'auth/login',
  async ({ formValue, cookies, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await apis.login(formValue);

      if (response.status === 200) {
        if (!response.data.token) {
          /*
          cookies.set('user-token', null, {
            path: '/',
            secure: true,
            sameSite: 'none',
          });
          */
        } else {
          toast.success('Connection Successfully!');
          navigate('/');

          cookies.set('user-token', response.data.token, {
            path: '/',
            secure: true,
            sameSite: 'none',
          });
        }
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        toast.error('Username and password not correct!');
      }

      return rejectWithValue(error.response.data);
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState: {
    data: null,
    userType: null,
    error: '',
    loading: false,
    isLogin: false,
  },
  reducers: {
    setErrorClear: (state, action) => {
      state.error = '';
    },
  },
  extraReducers: {
    [login.pending]: (state, action) => {
      state.loading = true;
    },
    [login.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.isLogin = true;
    },
    [login.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setErrorClear } = userSlice.actions;

export default userSlice.reducer;
