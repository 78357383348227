import axios from 'axios';

let baseURL = 'http://127.0.0.1:50717';

if (window.location.hostname == 'tracktruck.app') {
  baseURL = 'https://track-truck-be.herokuapp.com';
}
const API = axios.create({ baseURL });

export const login = (formData) => API.post('/login', formData);
export const createSeller = (formData, userToken) =>
  API.post('/sellers', formData, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: userToken,
    },
  });
export const getSellers = (userToken) =>
  API.get('/sellers', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ` + userToken,
    },
  });
export const generateMultiSubcodes = (sellerId, count, userToken) =>
  API.post('/sellers/' + sellerId + '/subCodes?count=' + count, '', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ` + userToken,
    },
  });

export const getSubcodesBySeller = (sellerId, userToken) =>
  API.get('/sellers/' + sellerId + '/subCodes?page=0&size=20', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ` + userToken,
    },
  });
