/* eslint-disable */
import React from 'react';

const Kvkk = () => {
  return (
    <div className="container">
      <div className="row">
        <h4 className="text-center">KVKK Metni</h4>
        <p>
          Bu metin, Türkiye Cumhuriyeti'nin Kişisel Verilerin Korunması Kanunu
          ("KVKK") kapsamında düzenlenmiştir ve aşağıdaki uygulama için
          geçerlidir: Track Truck.
        </p>
        <ol>
          <li>
            <h5>Veri Sorumlusu</h5>
            <p>
              Track Truck, veri sorumlusu olarak faaliyet göstermektedir.
              Şirketin iletişim bilgileri aşağıda belirtilmiştir: Tracktruck
              Yeni Mah., Selektör Sok., Saray/Tekirdağ +90 507 883 8242
              s.gokturk@outlook.com
            </p>
          </li>
          <li>
            <h5>Hangi Veriler Toplanıyor ve Nasıl Kullanılıyor?</h5>
            <p>
              Track Truck, kullanıcıların Google hesapları üzerinden giriş
              yapmasına olanak tanır. Google hesabı üzerinden giriş yapan
              kullanıcılardan e-posta adresi ve telefonunun id'si gibi bazı
              bilgiler toplanır. Bu bilgiler, kullanıcının oturumunu yönetmek ve
              hizmetlerimizi sunmak için kullanılır.
            </p>
            <p>
              Ayrıca, Track Truck verileri MongoDB Atlas'ta saklar. Bu veriler,
              yalnızca belirli personel tarafından erişilebilir ve sadece
              hizmetlerimizin sağlanması için kullanılır. Kameradan alınan
              veriler sadece kişilerin cihazında işlenir. Herhangi bir şekilde
              depolanmaz. 3. parti uygulamalar tarafından depolanmaz.
            </p>
          </li>
          <li>
            <h5>Kişisel Verilerin Saklanması</h5>
            <p>
              Track Truck, KVKK'nın belirlediği süre boyunca kullanıcılara ait
              kişisel verileri saklar. Bu süre boyunca, verilerin güvenliği için
              gerekli tüm önlemler alınır. Verilerin saklandığı sunucular,
              güvenlik ve gizlilik konularında endüstri standardı yöntemler
              kullanılarak korunur.
            </p>
          </li>
          <li>
            <h5>Kişisel Verilerin İletilmesi</h5>
            <p>
              Track Truck, kullanıcıların kişisel verilerini hiçbir koşulda
              üçüncü kişilere satmaz veya kiralamaz. Ancak, KVKK'nın öngördüğü
              haller dışında, bu veriler yalnızca yasal zorunluluklar altında
              veya kullanıcının açık rızası doğrultusunda paylaşılır.
            </p>
          </li>
          <li>
            <h5>Kullanıcıların KVKK Hakları</h5>
            <p>
              KVKK kapsamında, kullanıcılara bazı haklar tanınmaktadır.
              Kullanıcılar, veri sorumlusuna başvurarak, aşağıdaki haklarını
              kullanabilirler:
              <ul>
                <li>Kişisel verilerinin işlenip işlenmediğini öğrenme,</li>
                <li>
                  Kişisel verilerinin işlenme amacını ve bu amaç doğrultusunda
                  kullanıldığı üçüncü kişileri öğrenme,
                </li>
                <li>
                  Kişisel verilerinin eksik veya yanlış işlenmiş olması
                  durumunda bunların düzeltilmesi.
                </li>
              </ul>
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Kvkk;
