import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as apis from '../api';

export const createSeller = createAsyncThunk(
  'auth/createSeller',
  async ({ formValue, userToken, toast }, { rejectWithValue }) => {
    try {
      let tempFormData = {
        name: formValue.name,
        surname: formValue.surname,
        storeName: formValue.storeName,
        email: formValue.email,
        phone: formValue.phone,
        address: {
          district: formValue.district,
          city: formValue.city,
          number: parseInt(formValue.number),
          zip: formValue.zip,
          address: formValue.address,
          country: formValue.country,
        },
      };

      const response = await apis.createSeller(tempFormData, userToken);

      if (response.status === 201) {
        toast.success('Seller created successfully!');

        return response.data;
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSellers = createAsyncThunk(
  'auth/getSellers',
  async ({ userToken }, { rejectWithValue }) => {
    try {
      const response = await apis.getSellers(userToken);

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const generateMultiSubcodes = createAsyncThunk(
  'auth/generateMultiSubcodes',
  async ({ selected, number, token, toast }, { rejectWithValue }) => {
    try {
      const response = await apis.generateMultiSubcodes(
        selected,
        number,
        token
      );

      if (response.status === 200) {
        toast.success('SubCode created successfully!');

        return response.data;
      }
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSubcodesBySeller = createAsyncThunk(
  'auth/getSubcodesBySeller',
  async ({ sellerId, userToken }, { rejectWithValue }) => {
    try {
      const response = await apis.getSubcodesBySeller(sellerId, userToken);

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

const sellerSlice = createSlice({
  name: 'seller',
  initialState: {
    seller_data: null,
    sellers_data: null,
    seller_subCode: null,
    sellers_subCode: null,
    error: '',
    loading: false,
  },
  reducers: {
    setErrorClear: (state, _action) => {
      state.error = '';
    },
  },
  extraReducers: {
    [createSeller.pending]: (state, _action) => {
      state.loading = true;
    },
    [createSeller.fulfilled]: (state, action) => {
      state.loading = false;
      state.seller_data = action.payload;
    },
    [createSeller.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getSellers.pending]: (state, _action) => {
      state.loading = true;
    },
    [getSellers.fulfilled]: (state, action) => {
      state.loading = false;
      state.sellers_data = action.payload;
    },
    [getSellers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [generateMultiSubcodes.pending]: (state, _action) => {
      state.loading = true;
    },
    [generateMultiSubcodes.fulfilled]: (state, action) => {
      state.loading = false;
      state.seller_subCode = action.payload;
    },
    [generateMultiSubcodes.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getSubcodesBySeller.pending]: (state, _action) => {
      state.loading = true;
    },
    [getSubcodesBySeller.fulfilled]: (state, action) => {
      state.loading = false;
      state.sellers_subCode = action.payload;
    },
    [getSubcodesBySeller.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export const { setErrorClear } = sellerSlice.actions;

export default sellerSlice.reducer;
