//React
import React, { useState } from 'react';

//import { generateBearerToken } from '../../helpers/utils';
import Cookies from 'universal-cookie';

//Toastify
import { toast } from 'react-toastify';

//Redux Toolkit
import { useDispatch } from 'react-redux';
import { createSeller } from '../../redux/features/subCodeSlice';

const initialState = {
  name: '',
  surname: '',
  storeName: '',
  email: '',
  phone: '',
  district: '',
  city: '',
  number: '',
  zip: '',
  address: '',
  country: 'Turkey',
};

export default function Seller() {
  //States
  const [formValue, setFormValue] = useState(initialState);
  const {
    name,
    surname,
    storeName,
    email,
    phone,
    district,
    city,
    number,
    zip,
    address,
  } = formValue;

  const cookies = new Cookies();
  const userToken = `Bearer ${cookies.get('user-token')}`;

  const dispatch = useDispatch();

  const handleSeller = (e) => {
    e.preventDefault();

    if (formValue && userToken) {
      dispatch(
        createSeller({
          formValue,
          userToken,
          toast,
        })
      );
    }
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;

    setFormValue({ ...formValue, [name]: value });
  };

  return (
    <div className="container">
      <main className="row justify-content-center">
        <form className="col-md-4 col-12 my-3" onSubmit={handleSeller}>
          <h1 className="mb-3  text-center">Bayi Oluştur</h1>
          <input
            type="text"
            placeholder="Bayi Adi"
            name="storeName"
            className="form-control mb-3"
            defaultValue={storeName}
            onChange={onInputChange}
          />
          <input
            type="text"
            placeholder="Yetkili Adi"
            name="name"
            className="form-control mb-3"
            defaultValue={name}
            onChange={onInputChange}
          />
          <input
            type="text"
            placeholder="Yetkili Soyadi"
            name="surname"
            className="form-control mb-3"
            defaultValue={surname}
            onChange={onInputChange}
          />
          <input
            type="text"
            placeholder="İletişim Numarasi"
            name="phone"
            className="form-control mb-3"
            defaultValue={phone}
            onChange={onInputChange}
          />
          <input
            type="text"
            placeholder="Email"
            name="email"
            className="form-control mb-3"
            defaultValue={email}
            onChange={onInputChange}
          />
          <input
            type="text"
            placeholder="İl"
            name="city"
            className="form-control mb-3"
            defaultValue={city}
            onChange={onInputChange}
          />
          <input
            type="text"
            placeholder="İlçe"
            name="district"
            className="form-control mb-3"
            defaultValue={district}
            onChange={onInputChange}
          />
          <input
            type="text"
            placeholder="Kapi Numarasi"
            name="number"
            className="form-control mb-3"
            defaultValue={number}
            onChange={onInputChange}
          />
          <input
            type="text"
            placeholder="Posta Kodu"
            name="zip"
            className="form-control mb-3"
            defaultValue={zip}
            onChange={onInputChange}
          />
          <input
            type="text"
            placeholder="Adres"
            name="address"
            className="form-control mb-3"
            defaultValue={address}
            onChange={onInputChange}
          />
          <div className="d-grid gap-2">
            <button type="submit" className="btn btn-primary">
              Bayi Oluştur
            </button>
          </div>
        </form>
      </main>
    </div>
  );
}
