//React
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//Cookie
import Cookies from 'universal-cookie';

//Toastify
import { toast } from 'react-toastify';

//Redux Toolkit
import { useDispatch } from 'react-redux';
import { login } from '../../redux/features/userSlice';

//Css
import './Login.css';

//Utils
import { isLogin } from '../../helpers/utils';

const initialState = {
  email: '',
  password: '',
};

export default function Login() {
  //States
  const [formValue, setFormValue] = useState(initialState);
  const { email, password } = formValue;

  // eslint-disable-next-line
  const cookies = new Cookies();

  //Router
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    isLogin() && navigate('/');
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();

    if (formValue) {
      dispatch(
        login({
          formValue,
          cookies,
          navigate,
          toast,
        })
      );
    }
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;

    setFormValue({ ...formValue, [name]: value });
  };

  return (
    <div className="container">
      <main className="login text-center">
        <form onSubmit={handleLogin} className="col-md-4 col-12">
          <h1 className="mb-0">Giriş Yap</h1>
          <p className="mb-3">Track Truck</p>
          <div className="mb-2">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              name="email"
              defaultValue={email}
              onChange={onInputChange}
            />
          </div>
          <div className="mb-3">
            <input
              type="password"
              className="form-control"
              placeholder="Şifre"
              name="password"
              defaultValue={password}
              onChange={onInputChange}
            />
          </div>
          <div className="d-grid gap-2">
            <button className="btn btn-primary" type="submit">
              Giriş Yap
            </button>
          </div>
        </form>
      </main>
    </div>
  );
}
