import { configureStore } from '@reduxjs/toolkit';

//Reducers
import UserReducer from './features/userSlice';
import SellerReducer from './features/subCodeSlice';

export default configureStore({
  reducer: {
    user: UserReducer,
    seller: SellerReducer,
  },
});
