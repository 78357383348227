//React
import React, { useEffect } from 'react';

//Cookie
import Cookies from 'universal-cookie';

//JWT
import jwt_decode from 'jwt-decode';

//Redux Toolkit
import { useDispatch, useSelector } from 'react-redux';
import { getSubcodesBySeller } from '../../redux/features/subCodeSlice';

//Css
import './Homepage.css';

//Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines } from '@fortawesome/free-regular-svg-icons';

export default function Homepage() {
  //Redux Toolkit
  let { sellers_subCode } = useSelector((state) => ({
    ...state.seller,
  }));
  const dispatch = useDispatch();

  //Cookie
  const cookies = new Cookies();

  useEffect(() => {
    let userToken = null;
    userToken = cookies?.get('user-token');
    if (userToken) {
      let userCreds = jwt_decode(cookies.get('user-token'));
      let sellerId = userCreds.sellerId;

      dispatch(getSubcodesBySeller({ sellerId, userToken }));
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div className="container d-block">
      <main className="my-3 row justify-content-center">
        <div className="col-6">
          <div className="card mb-3 shadow-sm">
            <div className="row g-0">
              <div className="col-md-8">
                <div className="card-body">
                  <h5 className="card-title">Abonelik Kodları</h5>
                  <p className="card-text">
                    <h1>{sellers_subCode?.page?.totalElements}</h1>
                  </p>
                  <p className="card-text">
                    <small className="text-muted">
                      Kullanılmamış abonelik kodları
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          {/* <div className="card mb-3 shadow-sm">
            <div className="row g-0 py-0">
              <div className="col-md-8">
                <div className="card-body">
                  <h5 className="card-title">Uygulamayı İndirmek İçin</h5>
                  <p className="card-text">
                    <img
                      src={
                        process.env.PUBLIC_URL + '/assets/img/googleplay-qr.png'
                      }
                      className="card-img-top w-25"
                      alt="tracktruck-googleplay-qr"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </main>
      <main className="row justify-content-center">
        <div className="mb-3">
          <h3 className="m-0">Abonelik Kodları</h3>
          <span className="description">
            {sellers_subCode?.page?.totalElements} abonelik kodundan 20 tanesi
            gösteriliyor
          </span>
        </div>
        {sellers_subCode?.subCodes ? (
          <div>
            <div className="card p-3">
              <table className="table table-striped-columns">
                <tr>
                  <th scope="col">Abonelik Kodu</th>
                  <th scope="col">Durum</th>
                </tr>
                {sellers_subCode.subCodes.map((items, index) => (
                  <tr key={index}>
                    <td>{items.subCode}</td>
                    <td>Aktif</td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        ) : (
          <div className="col-md-4 col-12 text-center my-5">
            <h1>
              <FontAwesomeIcon
                icon={faFileLines}
                style={{ color: '#101010' }}
              />
            </h1>
            <p className="">
              Kullanılabilir abonelik kodu bulunamadı. <br />
              Abonelik kodu için iletişime geçebilirsiniz.
            </p>
            <button type="button" className="btn btn-outline-dark">
              Abonelik Kodu Satın Al
            </button>
          </div>
        )}
      </main>
    </div>
  );
}
