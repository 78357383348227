//React
import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

//Pages
import Homepage from './pages/Homepage/Homepage';
import Login from './pages/Login/Login';
import Seller from './pages/Seller/Seller';
import Support from './pages/Support/Support';
import MultiSubGeneratCode from './pages/MultiSubGenerate/MultiSubGenerateCode';
import SellerList from './pages/Seller/SellerList';
import Kvkk from './pages/Kvkk/Kvkk';

//Components
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';

//Toastify
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Css
import './App.css';
import { useSelector } from 'react-redux';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';

function App() {
  let { isLogin } = useSelector((state) => ({
    ...state.user,
  }));

  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        {/* <Route path="*" element={<NotFound />} /> */}

        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Homepage />} />

        <Route path="/support" element={<Support />} />

        <Route path="/kvkk" element={<Kvkk />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsConditions />} />

        {/* a request can access below urls if has admin role */}
        <Route path="/create-seller" element={<Seller />} />
        <Route path="/sellers" element={<SellerList />} />
        <Route
          path="/multi-sub-generate"
          element={<MultiSubGeneratCode to="/multi-sub-generate" />}
        />
      </Routes>
      <ToastContainer />
      {isLogin && <Footer />}
    </BrowserRouter>
  );
}

export default App;
