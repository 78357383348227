//React
import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <div className="container">
      <footer className="py-3 my-4">
        <ul className="nav justify-content-center border-bottom pb-3 mb-3">
          <Link to="/admin-stats" className="nav-link px-2 text-muted">
            İstatistikler
          </Link>
          <Link to="/create-seller" className="nav-link px-2 text-muted">
            Bayi Oluştur
          </Link>
          <Link to="/sellers" className="nav-link px-2 text-muted">
            Satıcılar
          </Link>
          <Link to="/multi-sub-generate" className="nav-link px-2 text-muted">
            Kod Oluştur
          </Link>
        </ul>
        <p className="text-center text-muted">
          © 2023 TrackTruck, Tüm Haklar Saklıdır
        </p>
      </footer>
    </div>
  );
}
