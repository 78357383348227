//React
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { isLogin } from '../../helpers/utils';
import { useNavigate } from 'react-router-dom';

//Css
import './Navbar.css';
import Cookies from 'universal-cookie';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileLines,
  faArrowAltCircleDown,
} from '@fortawesome/free-regular-svg-icons';

export default function Navbar() {
  const cookies = new Cookies();
  const navigate = useNavigate();

  const logoutHandler = () => {
    cookies.remove('user-token');
    navigate(0);
  };

  useEffect(() => {
    if (
      isLogin() == false &&
      window.location.pathname != '/kvkk' &&
      window.location.pathname != '/support' &&
      window.location.pathname != '/privacy-policy'
    ) {
      navigate('/login');
    }
  }, []);

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container">
        <Link to={isLogin() ? '/' : '/login'} className="navbar-brand">
          <div>TrackTruck</div>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item"></li>
            <li className="nav-item">
              <Link to="/support" className="nav-link">
                İletişim
              </Link>
            </li>
          </ul>

          <div className="d-flex">
            {isLogin() && (
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <div className="dropdown mr-5">
                    <a
                      className="nav-link dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Uygulamayı indir{' '}
                      <FontAwesomeIcon
                        icon={faArrowAltCircleDown}
                        style={{ color: '#101010' }}
                      />
                    </a>
                    <ul className="dropdown-menu p-0">
                      <li>
                        <a className="nav-link p-0">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              '/assets/img/googleplay-qr.png'
                            }
                            className="card-img-top w-100 p-0"
                            alt="tracktruck-googleplay-qr"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" onClick={() => logoutHandler()}>
                    Çıkış Yap
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
      {/* <nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        <Link to="/" className="navTitle active">
          <div>TrackTruck</div>
        </Link>

        <div className="navMid">
          <Link to="/support">İletişim</Link>
        </div>

        <div className="navEnd">
          {isLogin() ? (
            <button type="submit" onClick={() => logoutHandler()}>
              Çıkış Yap
            </button>
          ) : (
            <Link to="/login">Giriş Yap</Link>
          )}
        </div>
      </div>
    </nav> */}
    </nav>
  );
}
