import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

//Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines } from '@fortawesome/free-regular-svg-icons';

//Redux Toolkit
import { useDispatch, useSelector } from 'react-redux';
import { getSellers } from '../../redux/features/subCodeSlice';
import { useEffect } from 'react';

//Spinners
import ClipLoader from 'react-spinners/ClipLoader';

//Cookie
import Cookies from 'universal-cookie';

export default function SellerList() {
  //React Router
  const navigate = useNavigate();

  //Redux Toolkit
  let { sellers_data, loading } = useSelector((state) => ({
    ...state.seller,
  }));
  const dispatch = useDispatch();

  //Cookie
  const cookies = new Cookies();

  useEffect(() => {
    const userToken = `${cookies.get('user-token')}`;

    if (!userToken) {
      navigate('/login');
    } else {
      dispatch(getSellers({ userToken }));
    }

    console.log(sellers_data?.sellers[0]?.subCodes);
    console.log(sellers_data?.sellers[0]?.subCodes.length);
  }, []);

  return (
    <div className="container d-block">
      <main className="my-3 row justify-content-center">
        <div className="col-6">
          <div className="card mb-3 shadow-sm">
            <div className="row g-0">
              <div className="col-md-8">
                <div className="card-body">
                  <h5 className="card-title">Bayiler</h5>
                  <p className="card-text">
                    <h1>
                      {sellers_data?.sellers.length > 0
                        ? sellers_data?.sellers.length
                        : '0'}
                    </h1>
                  </p>
                  <p className="card-text">
                    <small className="text-muted">Tüm bayiler</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="card mb-3 shadow-sm">
            <div className="row g-0">
              <div className="col-md-8">
                <div className="card-body">
                  <h5 className="card-title">Aktif Bayiler</h5>
                  <p className="card-text">
                    <h1>
                      {sellers_data?.sellers.filter(
                        (seller) => seller.isActive == true
                      ).length > 0
                        ? sellers_data?.sellers.filter(
                            (seller) => seller.isActive == true
                          ).length
                        : '0'}
                    </h1>
                  </p>
                  <p className="card-text">
                    <small className="text-muted">Aktif bayiler</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <main className="row justify-content-center">
        <div className="mb-3">
          <h3 className="m-0">Bayiler</h3>
          <span className="description">
            Kayıtlı bayi:
            {sellers_data?.sellers.length > 0
              ? sellers_data?.sellers.length
              : '0'}
          </span>
        </div>
        {sellers_data?.sellers.length > 0 ? (
          <div>
            <div className="card p-3">
              <table className="table table-striped-columns">
                <tr>
                  <th scope="col">Bayi adı</th>
                  <th scope="col">Abonelik Kodu</th>
                  <th scope="col">Durum</th>
                  <th scope="col"></th>
                </tr>
                {sellers_data?.sellers.map((item, index) => (
                  <tr key={index}>
                    <td>{item.storeName}</td>
                    <td>{item.subCodes ? item.subCodes.length : 0}</td>
                    <td>{item.isActive ? 'Aktif' : 'Kapalı'}</td>
                    <td>
                      <Link
                        to={`/sellers/{seller.id}`}
                        className="btn btn-link"
                      >
                        Detaylar
                      </Link>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        ) : loading ? (
          <ClipLoader color={'#798eb3'} loading={loading} size={120} />
        ) : (
          <div className="col-md-4 col-12 text-center my-5">
            <h1>
              <FontAwesomeIcon icon={faFileLines} />
            </h1>
            <p className="">Kayıtlı bayi bulunamadı</p>
            <Link to="/create-seller" class="btn btn-outline-dark">
              Bayi oluştur
            </Link>
          </div>
        )}
      </main>
    </div>
  );
}
