//React
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//Redux Toolkit
import { useDispatch, useSelector } from 'react-redux';
import {
  getSellers,
  generateMultiSubcodes,
} from '../../redux/features/subCodeSlice';

//Toastify
import { toast } from 'react-toastify';

//Cookie
import Cookies from 'universal-cookie';

//Css
import './MultiSubGenerateCode.css';

export default function MultiSubGeneratCode() {
  const [selected, setSelected] = useState();
  const [number, setNumber] = useState();
  const [token, setToken] = useState();

  //Redux Toolkit
  let { sellers_data } = useSelector((state) => ({
    ...state.seller,
  }));
  const dispatch = useDispatch();

  //Cookie
  const cookies = new Cookies();

  //React Router
  const navigate = useNavigate();

  useEffect(() => {
    const userToken = `Bearer ${cookies.get('user-token')}`;

    if (!userToken) {
      navigate('/login');
    } else {
      setToken(userToken);

      dispatch(getSellers({ userToken }));
    }
    // eslint-disable-next-line
  }, []);

  const handleSelectChange = (e) => {
    setSelected(e.target.value);
  };

  const handleForm = (e) => {
    e.preventDefault();

    if (selected && number && token) {
      dispatch(
        generateMultiSubcodes({
          selected,
          number,
          token,
          toast,
        })
      );
    }
  };

  return (
    <div className="multisub">
      <div className="multisub_wrapper">
        <div className="multisub_title">
          <p>Abonelik Kodu Oluşturun</p>
        </div>
        <form className="multisub_form" onSubmit={handleForm}>
          <div className="multisub_form_inputs">
            <select name="cars" id="cars" onChange={handleSelectChange}>
              {sellers_data?.sellers ? (
                sellers_data?.sellers.map((items, index) => (
                  <option key={index} value={items.id}>
                    {items.storeName}
                  </option>
                ))
              ) : (
                <option key={0}>choose</option>
              )}
            </select>
            <br />
            <input type="number" onChange={(e) => setNumber(e.target.value)} />
          </div>
          <div className="multisub_form_button">
            <input type="submit" />
          </div>
        </form>
      </div>
    </div>
  );
}
